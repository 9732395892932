















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

export default Vue.extend({
  data() {
    return {
      colorOptions: {
        effect: "fade",
        speed: 1000,
        allowTouchMove: false,
        navigation: {
          prevEl: ".color-prev",
          nextEl: ".color-next"
        }
      },
      activeIndex: 0,
      communityOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        navigation: {
          prevEl: ".community-prev",
          nextEl: ".community-next"
        }
      },
      mediaOptions: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        navigation: {
          prevEl: ".media-prev",
          nextEl: ".media-next"
        }
      }
    };
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    title04(): string {
      if (this.isCN && !this.isMobile) {
        return String(this.$t("cyberblade.title04")).replace("<br />", " ");
      } else {
        return String(this.$t("cyberblade.title04"));
      }
    },

    communityNames(): string[] {
      return this.isCN
        ? [
            "@张千隐",
            "@张千隐",
            "@张千隐",
            "@张千隐",
            "@JJEF",
            "@GUZY",
            "@GUZY",
            "@GUZY",
            "@GUZY",
            "@肌肉肥羊",
            "@肌肉肥羊",
            "@肌肉肥羊",
            "@肌肉肥羊",
            "@肌肉肥羊",
            "@肌肉肥羊",
            "@EnvieD",
            "@EnvieD",
            "@cyansky",
            "@UX_有思",
            "@UX_有思",
            "@大R",
            "@mxcx5",
            "@st2tiger"
          ]
        : [
            "by Zhangqianyin",
            "by Zhangqianyin",
            "by Zhangqianyin",
            "by Zhangqianyin",
            "by JEFF",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by GUZY",
            "by Jiroufeiyang",
            "by Jiroufeiyang",
            "by Jiroufeiyang",
            "by Jiroufeiyang",
            "by Jiroufeiyang",
            "by Jiroufeiyang",
            "by EnvieD",
            "by EnvieD",
            "by cyansky",
            "by kennnwin",
            "by kennnwin",
            "by DaR",
            "by mxcx5",
            "by st2tiger"
          ];
    }
  },
  methods: {
    onDownload(url: string) {
      download(url);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberblade/", "_blank");
    },
    onSlideChange(swiper: any) {
      this.activeIndex = swiper.activeIndex;
    },
    toSlide(index: number) {
      (this.$refs.colorSwiper as any).$swiper.slideTo(index, 1000);
    }
  },
  mounted() {
    document.querySelectorAll(".cyberblade sup").forEach(sup => {
      sup.addEventListener("click", () => {
        location.href = "#line";
        setTimeout(() => {
          location.href = "#line";
        }, 100);
      });
    });
  }
});
